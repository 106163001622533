.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

// 입지환경 페이지 
.locationImg {
    width: 78%;
    margin: 3vw 0 2vw 0;

    @media (max-width: 900px) {
        width: 90%;
    }
}

.locationImg:first-of-type {
    margin: 4vw 0 2vw 0;
}

.textBox {
    margin: 4vw 0 0 0;
    text-align: center;
    font-size: 1.5vw;

    div:nth-of-type(1) {
        font-weight: 200;
    }

    div:nth-of-type(2) {
        margin-top: 1vw;
        font-weight: 800;
        font-size: 2.5vw;
        margin-bottom: 1vw;
        color: #1b2d53;
    }

    div:nth-of-type(3) {
        margin-top: 1vw;
        font-size: 1.5vw;
    }
}

.section2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: stretch; // 변경된 부분
    gap: 2vw;
    width: 78%;

    @media (max-width: 900px) {
        width: 90%;
        grid-template-columns: repeat(1, 1fr);
    }

    img {
        width: 100%; /* 부모 요소에 맞게 100% */
        height: auto; /* 자동 높이 설정 */
        max-height: 55vh; /* 최대 높이 설정 */
        object-fit: cover; /* 비율 유지하면서 크기 조정 */
        opacity: 0; /* 초기 상태에서 요소를 숨깁니다. */
        transform: translateY(20px); /* 초기 위치를 아래로 이동 */
        animation: slide-up 1.5s forwards; /* 애니메이션 적용 */
    }
}

@keyframes slide-up {
    from {
        opacity: 0; /* 애니메이션 시작 시 투명도 */
        transform: translateY(20px); /* 애니메이션 시작 시 아래로 이동 */
    }
    to {
        opacity: 1; /* 애니메이션 종료 시 불투명 */
        transform: translateY(0); /* 애니메이션 종료 시 원래 위치로 복귀 */
    }
}

// 프리미엄 페이지
.premiumImage {
    margin: 1vw 0 0 0;
    width: 80%;

    @media (max-width: 900px) {
        width: 90%;
    }
}

// 하단 설명 박스
.commonBox {
    margin: 2vw 0;
    width: 80%;
    background-color: #f8f4f0;
    border: 1px solid #e9e2da;

    @media (max-width: 900px) {
        width: 90%;
    }

    .notice {
        padding: 0 1vw;
        line-height: 1.1vw;
        font-size: 1vw;
        color: #ada8a2;

        @media (max-width: 900px) {
            padding: 0 3vw;
            font-size: 2.5vw;
            line-height: 3vw;
            text-indent: -2vw;
        }
    }

    .notice:nth-of-type(1) {
        padding-top: 1vw;
        padding-bottom: 1vw;
    }

    .notice:nth-of-type(2),
    .notice:nth-of-type(3),
    .notice:nth-of-type(4),
    .notice:nth-of-type(5) {
        padding-bottom: 1vw;
    }

    .notice:nth-of-type(6) {
        padding-bottom: 1vw;
    }
}