.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bener{
    width: 100vw;
    height: auto;
}

.img1 {
    margin: 3vw 0 0 0 ;
    width: 70%;

    @media (max-width: 900px) {
        margin: 4vw 0 4vw 0 ;
        width: 80%;
    }

}

.img2 {
    margin: 3vw 0 0 0 ;
    width: 70%;

    @media (max-width: 900px) {
        margin: 4vw 0 0 0 ;
        width: 90%;
    }

}

.img3 {
    margin: 3vw 0 0 0 ;
    width: 70%;

    @media (max-width: 900px) {
        margin: 2vw 0 4vw 0 ;
        width: 90%;
    }

}


.commonBox {
    margin: 2vw 0;
    width: 80%;
    background-color: #f8f4f0;
    border: 1px solid #e9e2da;

    @media (max-width: 900px) {
        width: 90%;
    }

    .notice {
        padding: 0 1vw;
        line-height: 1.1vw;
        font-size: 1vw;
        color: #ada8a2;

        @media (max-width: 900px) {
            padding: 0  3vw;
            font-size: 2.5vw;
            line-height: 3vw;
            text-indent: -2vw;
        }
    }

    .notice:nth-of-type(1) {
        padding-top: 1vw;
        padding-bottom: 1vw;
    }

    .notice:nth-of-type(2),
    .notice:nth-of-type(3),
    .notice:nth-of-type(4),
    .notice:nth-of-type(5) {
        padding-bottom: 1vw;
    }

    .notice:nth-of-type(6) {
        padding-bottom: 1vw;
    }
}