.imageContainer {
    position: relative;
    width: 100%;
    height: 100vh;
    display: block;
    overflow: hidden; /* 이미지가 화면을 벗어날 때 숨김 */
  
    .mainImage {
      width: 100%;
      height: 100vh;
      display: block;
      opacity: 0;
      transform: scale(1.1); /* 초기 상태에서 약간 커짐 */
      animation: zoomIn 2s ease-out forwards; /* 이미지 줌 인 효과 */
    }
  
    .mainImageTextBox {
      font-family: 'GongGothicMedium';
      position: absolute;
      top: 20vw;
      left: 75vw;
      transform: translate(5%, -50%);
      margin-left: 3vw;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: left;
  
      .mainImageText1, .mainImageText2, .mainImageText3 {
        font-size: 1.5vw;
        display: inline-block;
        opacity: 0;
        transform: translateX(-50px); /* 텍스트 슬라이드 인 시작 위치 */
        animation: slideIn 1s ease-out forwards;
        letter-spacing: -0.001vw; /* 글자 간격 좁히기 */
      }
  
      .mainImageText2 {
        margin-top: 0.4vw;
        font-size: 1.5vw;
  
        span {
          letter-spacing: -0.001vw; /* 스팬 내의 글자 간격도 좁힘 */
        }
      }
  
      .mainImageText3 {

        margin-top: 0.5vw;
        font-size: 2vw;
        display: inline-block;
        color: #1b2d53;
  
    
      }
  
      /* 각 텍스트 애니메이션 순차적으로 적용 */
      .mainImageText1 {
        animation-delay: 0.3s;
      }
      .mainImageText2 {
        animation-delay: 0.6s;
      }
      .mainImageText3 {
        animation-delay: 0.9s;
      }
  
      /* 텍스트 글자마다 슬라이드 애니메이션 적용 */
      .mainImageText1 span,
      .mainImageText2 span,
      .mainImageText3 span {
        display: inline-block;
        opacity: 0;
        transform: translateX(-20px); /* 각 글자도 슬라이드 인 */
        animation: letterSlideIn 0.5s ease-out forwards;
      }
  
      .mainImageText1 span:nth-child(1) {
        animation-delay: 0.3s;
      }
  
      .mainImageText2 span:nth-child(1) {
        animation-delay: 0.6s;
      }
  
      .mainImageText3 span:nth-child(1) {
        animation-delay: 0.9s;
      }
    }
  }
  
  /* Zoom-in 애니메이션 */
  @keyframes zoomIn {
    to {
      opacity: 1;
      transform: scale(1); /* 초기 크기로 돌아감 */
    }
  }
  
  /* 텍스트 슬라이드 인 애니메이션 */
  @keyframes slideIn {
    to {
      opacity: 1;
      transform: translateX(0); /* 최종 위치로 이동 */
    }
  }
  
  /* 글자마다 슬라이드 인 애니메이션 */
  @keyframes letterSlideIn {
    to {
      opacity: 1;
      transform: translateX(0); /* 글자가 최종 위치로 이동 */
    }
  }
  
  



.section1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #fffbf5;

    .textBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 7rem;
        padding-top: 9rem;

        font-family: 'HakgyoansimBareonbatangB';
        font-weight: 700;
        font-size: 3.5vw;

        .text1 {
            font-size: 2.5vw;
            color: #c98f25;
        }

        .text2 {
            margin-top: 3vw;
            font-size: 1.5vw;
            color: #461900;
        }

        .text3 {
            margin-top: 4vw;
            font-size: 1vw;
            line-height: 2vw;
            color: #a2978a;
        }

        .text4 {
            margin-top: 5vw;
            margin-left: 2vw;
            font-size: 1.2vw;
            color: #241400;

            a {
                text-decoration: none;
                color: inherit;
            }
        }
    }

    .menuBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50vw;
        height: 100%;
        background-color: #FFFFFF;

        .text {
            margin-top: 1.5vw;
            color: #1b2d53;
            font-weight: 900;
            font-size: 2vw;

            span {
                padding: 0.5vw 1vw;
                background-color: #1b2d53;
                border-bottom-left-radius: 1vw;
                border-top-right-radius: 1vw;

                color: #FFFFFF;
                font-weight: 500;
            }
        }

        .btn {
            margin-top: 2vw;
            padding: 0.8vw 1.2vw;
            border: 2px solid #1b2d53;
            border-radius: 10vw;
            color: #1b2d53;
            font-size: 1.2vw;
            font-weight: 800;
            text-decoration: none;
        }
    }
}

.section2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 100vh;

    background-color: #fffbf5;

    .textBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        margin: 8vw 0 0 2.5vw;
        width: 100%;

        .title {
            color: #1b2d53;
            font-family: 'HakgyoansimBareonbatangB';
            margin-top: 5vw;
            font-size: 2vw;
            font-weight: 400;
            line-height: 3.5vw;

            span:first-of-type {
                color: #b88c52;
                font-weight: 800;
                font-size: 3vw;
            }
        }

        .subTitle {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 1vw;
            margin-top: 2vw;
            width: 100%;

            .textLine {
                border: 1.7px solid #1b2d53;
                width: 0;
                height: 3.5vw;
            }

            .subText {
                font-size: 1.5vw;
                line-height: 2vw;
            }

        }
    }

    img {
        margin: 10vw 0 0 0;
        width: 45vw;

    }
}

.section3 {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right, #fff6ea 85%, #1b2d53 15%);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .textBox {
        margin-top: 14vw;
        margin-left: 5.5vw;

        font-family: 'HakgyoansimBareonbatangB';
        font-weight: 700;
        font-size: 3.5vw;

        .text1,
        .text2,
        .text3,
        .text4,
        .text5 {
            opacity: 0;
            animation: fadeUp 1.8s ease forwards;
        }

        .text1 {
            font-size: 1.8vw;
            animation-delay: 0.3s;
            color: #c98f25;
        }

        .text2 {
            margin-top: 1.0vw;
            line-height: 2.5vw;
            font-size: 2vw;
            animation-delay: 0.6s;
            color: #1b2d53;
        }

        .text3 {
            margin-top: 3.8vw;
            font-size: 1.3vw;
            animation-delay: 0.9s;
            color: #a2978a;
        }

        .text4 {
            margin-top: 1.5vw;
            line-height: 2vw;
            font-size: 1.5vw;
            animation-delay: 1.2s;
            color: #1b2d53;
        }

        .text5 {
            margin-top: 1.5vw;
            line-height: 1.8vw;
            font-size: 1vw;
            animation-delay: 1.5s;
            color: #5e5e5e;
        }

        span {
            font-weight: 900;
        }
    }

    img {
        margin-top: 15vw;
        margin-right: 5rem;
        width: 40vw;
    }
}

.section4 {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1vw;

        width: 25%;
        height: 100vh;
        background-color: #fff6ea;

        img {
            width: 7vw;
        }
    }

    .boxTitle {
        font-family: 'HakgyoansimBareonbatangB';
        font-weight: 700;
        margin-top: 2vw;
        font-size: 2vw;
        color: #b88c52;
    }

    .boxText1 {
        text-align: center;
        margin-top: 2vw;
        font-size: 1.2vw;
        line-height: 1.5vw;
    }

    .boxText2 {
        text-align: center;
        margin-top: 2vw;
        font-size: 1vw;
        line-height: 1.5vw;
        color: #968978;
    }

    .boxText3 {
        text-decoration: none;
        font-family: 'HakgyoansimBareonbatangB';
        text-align: center;
        margin-top: 2.5vw;
        font-size: 1.2vw;
        color: #fff6ea;
        text-decoration-color: #fff6ea;
    }

    .box:hover {
        background-color: #1b2d53;
        color: #fff7ea;
    }
}

.section5 {
    display: flex;
    flex-direction: row;

    width: 100vw;
    height: 100%;

    background-color: #fff6ea;

    .imageBox {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 40vw;
            height: 100vh;
        }

        .text1,
        .text2,
        .text3 {
            width: 40vw;
            position: absolute;
            color: #FFFFFF;
            text-align: center;
        }

        .text1 {
            top: 47%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #FFFFFF;
            font-size: 1.5vw;
            font-family: "HakgyoansimBareonbatangB";
        }

        .text2 {
            top: 53%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #d1af73;
            font-size: 2.5vw;
            font-family: "GmarketSansMedium";
        }

        .text3 {
            top: 62%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #FFFFFF;
            font-size: 1.2vw;
            font-family: "GmarketSansMedium";
        }
    }

    .textBox {
        margin-top: 8vw;
        margin-left: 4vw;

        width: 45vw;

        .text1 {
            font-family: "HakgyoansimBareonbatangB";
            font-size: 2.2vw;
            color: #c98f25;
        }

        .text2 {
            position: relative;
            top: 2vw;
            left: 90%;
            width: 100%;
            text-align: center;
            text-decoration: none;
            font-family: "HakgyoansimBareonbatangB";
            font-size: 1.5vw;
            color: #d1af73;
        }
    }
}

.section6 {
    width: 100vw;
    height: 100%;
}

//모바일 메인 스타일

.mobileMain {

    .imageContainer {
        position: relative; // Make this relative to allow absolute positioning of overlay
        width: 100%;
        height: 100%;

        .mainImage {
            margin-top: 6vw;
            width: 100vw;
            height: 130vw;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100%; // Match the container's size
            background-color: rgba(0, 0, 0, 0.4); // 40% opacity
            pointer-events: none;
        }

        .mainImageTextBox {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            position: absolute;
            top: 50%;
            left: 3%;

            transform: translate(5%, -50%);
            color: #ffe2b0;

            .mainImageTitle {
                font-family: 'HakgyoansimBareonbatangB';
                font-weight: 700;
                font-size: 5vw;
            }

            .mainImageTextSub {
                margin-top: 1.3vw;
                font-family: 'HakgyoansimBareonbatangA';
                font-weight: 200;
                font-size: 3vw;
            }

            .mainImageLine {
                margin: 2vw 0;
                height: 10vw;
                border-left: 2px solid #ffe2b0;
            }

            .mainImageText {
                font-family: 'HakgyoansimBareonbatangB';
                font-weight: 700;
                font-size: 4.5vw;
            }
        }

        .mainImageTextBox div {
            display: inline-block;
            opacity: 0;
            transform: translateY(20px);
            animation: fadeUp 1.5s ease forwards;
        }

        .mainImageTextBox div:nth-child(1) {
            animation-delay: 0.3s;
        }

        .mainImageTextBox div:nth-child(2) {
            animation-delay: 0.6s;
        }

        .mainImageTextBox div:nth-child(3) {
            animation-delay: 0.9s;
        }

        .mainImageTextBox div:nth-child(4) {
            animation-delay: 1.2s;
        }

        @keyframes fadeUp {
            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    .container1 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        padding: 10vw 0 0 7vw;

        font-family: 'HakgyoansimBareonbatangB';
        font-weight: 700;

        background-color: #fffbf5;

        .text1 {
            font-size: 8vw;
            color: #c98f25;
        }

        .text2 {
            margin-top: 8vw;
            font-size: 3vw;
            color: #1b2d53;
        }

        .text3 {
            margin-top: 8vw;
            font-size: 2.5vw;
            font-family: 'HakgyoansimBareonbatangA';
            font-weight: 300;
            line-height: 4vw;
            color: #928677;
        }

        .text4 {
            margin: 15vw 0 10vw 3vw;
            font-size: 3vw;
            color: #241400;

            a {
                text-decoration: none;
                color: inherit;
            }
        }

    }

    .container2 {
        width: 100%;
        height: 100%;

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5vw;

            margin: 30vw 0;

            img{
                width: 30vw;
            }

            .btn {
                margin-top: 0.5vw;
                padding: 1.5vw 2vw;
                border: 2px solid #1b2d53;
                border-radius: 10vw;
                color: #1b2d53;
                font-size: 3vw;
                font-weight: 800;
                text-decoration: none;
            }
        }
    }

    .container3 {
        position: relative;
        padding-top: 12vw;
        padding-left: 5.5vw;

        height: 80vw;
        background: linear-gradient(to bottom, #fff6ea 85%, #1b2d53 15%);

        .textbox {
            font-family: 'HakgyoansimBareonbatangB';
            font-weight: 700;
            font-size: 3.5vw;

            .text1,
            .text2,
            .text3,
            .text4,
            .text5 {
                opacity: 0;
                animation: fadeUp 1.8s ease forwards;
            }

            .text1 {
                font-size: 4vw;
                animation-delay: 0.3s;
                color: #c98f25;
            }

            .text2 {
                margin-top: 2vw;
                font-size: 3.5vw;
                line-height: 5vw;
                animation-delay: 0.6s;
                color: #1b2d53;
            }

            .text3 {
                margin-top: 5.8vw;
                font-size: 3.5vw;
                animation-delay: 0.9s;
                color: #a2978a;
            }

            .text4 {
                margin-top: 2.5vw;
                font-size: 3vw;
                line-height: 3.5vw;
                animation-delay: 1.2s;
                color: #1b2d53;
            }

            .text5 {
                margin: 3vw 0;
                font-size: 2.2vw;
                line-height: 3.2vw;
                animation-delay: 1.5s;
                color: #5e5e5e;
            }

            span {
                font-weight: 900;
            }
        }

        img {
            position: absolute;
            bottom: 3vw;
            right: 6vw;

            width: 30vw;
        }
    }
}

.container4 {
    width: 100%;
    height: 100%;
    background-color: #fff6ea;

    .text1 {
        padding: 8vw 0 0 6vw;
        font-family: "HakgyoansimBareonbatangB";
        font-size: 5vw;
        color: #c98f25;
    }

    .text2 {
        width: 100%;
        text-align: center;
        text-decoration: none;
        font-family: "HakgyoansimBareonbatangB";
        font-size: 1.5vw;
        color: #d1af73;

        div {
            @media (max-width: 900px) {
                padding: 6vw 4vw 7vw 0;
                text-align: right;
                font-size: 4vw;
            }
        }
    }
}

.container5 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 100vw;
        height: 90vw;
    }

    .text1,
    .text2,
    .text3 {
        width: 100%;
        position: absolute;
        color: #FFFFFF;
        text-align: center;
    }

    .text1 {
        top: 38%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF;
        font-size: 3vw;
        font-family: "HakgyoansimBareonbatangB";
    }

    .text2 {
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #d1af73;
        font-size: 5vw;
        font-family: "GmarketSansMedium";
    }

    .text3 {
        top: 58%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF;
        font-size: 3.5vw;
        font-family: "GmarketSansMedium";
    }
}

.container7 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    background-color: #fff8ee;

    .textBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        margin: 4vw 0 0 2.5vw;
        width: 100%;

        .title {
            color: #1b2d53;
            font-family: 'HakgyoansimBareonbatangB';
            font-size: 4vw;
            font-weight: 400;
            line-height: 6vw;

            span:first-of-type {
                color: #b88c52;
                font-weight: 800;
                font-size: 5vw;
            }
        }

        .subTitle {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 1vw;
            margin-top: 2vw;
            width: 100%;

            .textLine {
                border: 1.7px solid #00141b;
                width: 0;
                height: 7vw;
            }

            .subText {
                margin: 0 0 0 1.5vw;
                font-size: 3vw;
                line-height: 4vw;
            }

        }
    }

    img {
        margin: 2vw 0 0 0;
        width: 100%;

    }
}
